import React from "react";
import "./tileCard.css";
import { Fade } from "react-reveal";

export default function tileCard({ object, theme }) {
  function opentileinNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <div
      className="tile-card-div"
      style={{ backgroundColor: theme.highlight }}
    >
      <Fade bottom duration={1000} distance="40px">
            <div key={object.id} onClick={() => opentileinNewTab(object.url)}>
              <div className="tile-name-div">
                <p className="tile-name" style={{ color: theme.text }}>
                  {object.name}
                </p>
                <div className="card-img">
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    src={require(`../../assets/images/${object.logo_path}`)}
                    alt={object.name} 
                  />
                </div>
              </div>
              <p className="tile-description" style={{ color: theme.text }}>
                {object.description}
              </p>
              <div className="tile-details">
                <p
                  className="tile-creation-date subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {object.period.split("T")[0]}
                </p>
              </div>
            </div>
      </Fade>
    </div>
  );
}
