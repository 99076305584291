import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TileCard from "../../components/tileCard/tileCard";
// import Button from "../../components/button/Button";
import TopButton from "../../components/topButton/TopButton";
import { Fade } from "react-reveal";
// import { greeting, experiencesHeader, experiencesSubHeader } from "../../portfolio.js";
import { withTranslation } from "react-i18next";
import "./Experience.css";
import experienceImg from "../../assets/images/experiences.svg";
// import ExperienceImg from "./ExperienceImg";

class Experiences extends Component {
  render() {
    const theme = this.props.theme;
    const { t } = this.props;
    return (
      <div className="experiences-main">
        <Header theme={theme} />
        <div className="basic-experiences">
          <Fade bottom duration={2000} distance="40px">
            <div className="experiences-heading-div">
              <div className="experiences-heading-img-div">
                <img src={experienceImg} alt="" />
              </div>
              <div className="experiences-heading-text-div">
                <h1
                  className="experiences-heading-text"
                  style={{ color: theme.text }}
                >
                  {t("experience.headerTitle")}
                </h1>
                <p
                  className="experiences-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("experience.headerDescription")}
                </p>
              </div>
            </div>
          </Fade>
        </div>

        {/* Experiences  */}

        <div className="basic-experiences">
          <Fade bottom duration={2000} distance="40px">
            <div className="publications-heading-div">
              <div className="publications-heading-text-div">
                <h1
                  className="publications-heading-text"
                  style={{ color: theme.text }}
                >
                  {t("experience.subHeaderTitle")}
                </h1>
                <p
                  className="experiences-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("experience.subHeaderDescription")}
                </p>
              </div>
            </div>
          </Fade>
        </div>

        <div className="experience-cards-div-main">
          {t("experience.experiences", { returnObjects: true }).map(
            (experience) => {
              return <TileCard object={experience} theme={theme} />;
            }
          )}
        </div>

        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default withTranslation()(Experiences);
