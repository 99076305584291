import React, { Component } from "react";
import "./Educations.css";
import DegreeCard from "../../components/degreeCard/DegreeCard.js";
import { Fade } from "react-reveal";
import { withTranslation } from "react-i18next";

class Educations extends Component {
  render() {
    const theme = this.props.theme;
    const { t } = this.props;
    return (
      <div className="main" id="educations">
        <div className="educations-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="educations-header" style={{ color: theme.text }}>
              {t("education.degreesHeader")}
            </h1>
          </Fade>
        </div>
        <div className="educations-body-div">
          {t("education.degrees", { returnObjects: true }).map((degree) => {
            return <DegreeCard degree={degree} theme={theme} />;
          })}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Educations);
