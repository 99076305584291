import React, { Component } from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import { NavLink, Link } from "react-router-dom";
import { logoText, settings } from "../../portfolio.js";
import SeoHeader from "../seoHeader/SeoHeader";
import { withTranslation } from "react-i18next";

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};

class Header extends Component {
  render() {
    const theme = this.props.theme;
    const link = settings.isSplash ? "/splash" : "home";
    const { t, i18n } = this.props;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return (
      <Fade top duration={1000} distance="20px">
        <SeoHeader />
        <div className="top">
          <div className="languageswitcher">
            <div className="switcherButtons">
              <img
                className="switcherButton"
                src={require("../../assets/images/en.png")}
                onClick={() => changeLanguage("en")}
                alt="English"
              />
              <img
                className="switcherButton"
                src={require("../../assets/images/nl.png")}
                onClick={() => changeLanguage("nl")}
                alt="Nederlands"
              />
              {/*             <Link className='btnTxt' onClick={() => changeLanguage('en')}>EN</Link>
             <Link className='btnTxt' onClick={() => changeLanguage('nl')}>NL</Link> */}
            </div>
          </div>
          <div>
            <header className="header">
              <NavLink to={link} tag={Link} className="logo">
                {/* <span style={{ color: theme.text }}> &lt;</span> */}
                <span className="logo-name" style={{ color: theme.text }}>
                  {logoText.logo_name}
                </span>
                <div class="clear"></div>
                <span className="sublogo-name" style={{ color: theme.text }}>
                  {logoText.sublogo_name}
                </span>
                {/* <span style={{ color: theme.text }}>/&gt;</span> */}
              </NavLink>
              <input className="menu-btn" type="checkbox" id="menu-btn" />
              <label className="menu-icon" htmlFor="menu-btn">
                <span className="navicon"></span>
              </label>
              <ul className="menu" style={{ backgroundColor: theme.body }}>
                <li>
                  <NavLink
                    to="/home"
                    tag={Link}
                    activeStyle={{ fontWeight: "bold" }}
                    style={{ color: theme.text }}
                    onMouseEnter={(event) =>
                      onMouseEnter(event, theme.highlight)
                    }
                    onMouseOut={(event) => onMouseOut(event)}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/education"
                    tag={Link}
                    activeStyle={{ fontWeight: "bold" }}
                    style={{ color: theme.text }}
                    onMouseEnter={(event) =>
                      onMouseEnter(event, theme.highlight)
                    }
                    onMouseOut={(event) => onMouseOut(event)}
                  >
                    {t("header.educationButton")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/experience"
                    tag={Link}
                    activeStyle={{ fontWeight: "bold" }}
                    style={{ color: theme.text }}
                    onMouseEnter={(event) =>
                      onMouseEnter(event, theme.highlight)
                    }
                    onMouseOut={(event) => onMouseOut(event)}
                  >
                    {t("header.experienceButton")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/projects"
                    tag={Link}
                    activeStyle={{ fontWeight: "bold" }}
                    style={{ color: theme.text }}
                    onMouseEnter={(event) =>
                      onMouseEnter(event, theme.highlight)
                    }
                    onMouseOut={(event) => onMouseOut(event)}
                  >
                    {t("header.projectsButton")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact"
                    tag={Link}
                    activeStyle={{ fontWeight: "bold" }}
                    style={{ color: theme.text }}
                    onMouseEnter={(event) =>
                      onMouseEnter(event, theme.highlight)
                    }
                    onMouseOut={(event) => onMouseOut(event)}
                  >
                    {t("header.contactButton")}
                  </NavLink>
                </li>
              </ul>
            </header>
          </div>
        </div>
      </Fade>
    );
  }
}
export default withTranslation()(Header);
