import React, { Component } from "react";
import "./ProjectsList.css";
import { Fade } from "react-reveal";

class ProjectsList extends Component {
  render() {
    return (
      <div className="projects-main-div">
        <ul className="dev-icons-proj">
          {this.props.list.map((project) => {
            return (
                <li className="projects-inline" name={project.name}>
                  <Fade bottom duration={2000} distance="40px">
                    <img
                      className="projects-img"
                      src={require("../../assets/images/" + project.logo_path)}
                      alt={project.name}
                    />
                    <span
                       classname="projectName">
                       {project.name}
                    </span>
                  </Fade>
                </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default ProjectsList;
