import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import { Fade } from "react-reveal";
import ProjectsData from "../../projectsList.json";
import "./Projects.css";
// import ProjectsImg from "./ProjectsImg";
import projectsImg from "../../assets/images/projects.svg";
import ProjectsList from "../../components/projectsList/ProjectsList";
import { withTranslation } from "react-i18next";

class Projects extends Component {
  render() {
    const theme = this.props.theme;
    const { t } = this.props;
    return (
      <div className="projects-main">
        <Header theme={theme} />
        <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div className="projects-heading-div">
              <div className="projects-heading-img-div">
                <img src={projectsImg} alt="" />
              </div>
              <div className="projects-heading-text-div">
                <h1
                  className="projects-heading-text"
                  style={{ color: theme.text }}
                >
                  {t("projects.headerTitle")}
                </h1>
                <p
                  className="projects-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {t("projects.headerDescription")}
                </p>
              </div>
            </div>
          </Fade>
        </div>

        <div id="projectsList">
          <div className="projectsList-header-div">
            <Fade bottom duration={2000} distance="20px">
              <h1 className="projectsList-header" style={{ color: theme.text }}>
                {t("projects.subHeaderTitle")}
              </h1>
            </Fade>
          </div>
          <ProjectsList list={ProjectsData["data"]} />
        </div>

        {/* niet meer in gebruik. te grote tegels. kleine van hierboven mooier

        <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div className="publications-heading-div">
              <div className="publications-heading-text-div">
                <h1
                  className="publications-heading-text"
                  style={{ color: theme.text }}
                >
                  {projectsSubHeader.title}
                </h1>
                <p
                  className="projects-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {projectsSubHeader["description"]}
                </p>
              </div>
            </div>
          </Fade>
        </div>

        { /*        <div className="project-cards-div-main">
          {projectsData.data.map((project) => {
            return <TileCard object={project} theme={theme} />;
          })}
        </div> 

        */}
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default withTranslation()(Projects);
