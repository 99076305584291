/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "teleos IT Consultancy",
  description:
    "I help organizations in designing and delivering future proof IT solutions",
  og: {
    title: "teleos IT Consultancy",
    type: "website",
    url: "https://www.teleos.it/",
  },
};

//Home Page
const logoText = {
  logo_name: "teleos",
  sublogo_name: "IT Consultancy",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/kooskaspers/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/kooskaspers/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "mail",
    link: "mailto:info@teleos.it",
    fontAwesomeIcon: "far fa-envelope",
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Gitlab",
    link: "https://gitlab.com/kooskaspers",
    fontAwesomeIcon: "fa-gitlab", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
];

// How to change the icons on homepage under what i do section?
//
//         This section pulls data from skills in portfolio.js file.
//         Visit this website: https://icon-sets.iconify.design/
//         Search for the skill you are looking to add.
//         Select the icon of your choice.
//         Copy the text beside Selected Icon and replace it with fontAwesomeClassName of that particular softwareSkill.

const certifications = {
  certifications: [
    {
      title: "Certified Kubernetes Administrator (The Linux Foundation)",
      subtitle: "Credential ID: LF-vob4l4i4ud",
      logo_path: "k8s.png",
      certificate_link:
        "https://training.linuxfoundation.org/certification/certified-kubernetes-administrator-cka/",
      alt_name: "The Linux Foundation",
      // bodyColor: "#017E84",
      // headerColor: "#36576B",
    },
    {
      title: "AWS Certified Solutions Architect (Amazon Web Services)",
      subtitle: "Credential ID: 406420859",
      logo_path: "aws.jpg",
      certificate_link:
        "https://aws.amazon.com/certification/certified-solutions-architect-associate/",
      alt_name: "AWS",
      // bodyColor: "#017E84",
      // headerColor: "#36576B",
    },
    {
      title: "Scaled Agile Framework Agilist (Scaled Agile, Inc.)",
      subtitle: "Credential ID: 30526171-1308",
      logo_path: "safe.webp",
      certificate_link: "https://scaledagile.com/safe-certification/",
      alt_name: "AWS",
      // bodyColor: "#017E84",
      // headerColor: "#36576B",
    },
    {
      title: "Foundation Certificate in Business Analysis",
      // subtitle: "Credential ID: 30526171-1308",
      logo_path: "bcs.png",
      certificate_link:
        "https://www.bcs.org/qualifications-and-certifications/certifications-for-professionals/business-analysis/foundation-certificate-in-business-analysis/",
      alt_name: "BCS",
      // bodyColor: "#017E84",
      // headerColor: "#36576B",
    },
    {
      title: "APMG-International PRINCE2® Professional Certification",
      // subtitle: "Credential ID: 30526171-1308",
      logo_path: "p2.png",
      certificate_link:
        "https://apmg-international.com/sites/default/files/documents/products/prince2_pro_intro.pdf",
      alt_name: "APMG Prince 2",
      // bodyColor: "#017E84",
      // headerColor: "#36576B",
    },
    {
      title: "ITIL Foundation Certificate in IT Service Management",
      // subtitle: "Credential ID: 30526171-1308",
      logo_path: "itil.png",
      certificate_link: "https://www.exin.com/itil-foundation/",
      alt_name: "APMG Prince 2",
      // bodyColor: "#017E84",
      // headerColor: "#36576B",
    },
  ],
};

const contactPageData = {
  resumeLink:
    "https://koos.kaspers.cloud/s/7Km6g8SoWA5E76K/download/CV%20NL.pdf",
};

export {
  settings,
  seo,
  logoText,
  socialMediaLinks,
  certifications,
  contactPageData,
};
